@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);
#display-container {
  padding: 0.5em;
  font-size: 1.5rem;
  background-color: #222;
  grid-area: display;
  border-radius: 30px 30px 0 0;
  text-align: right;
  color: white;
  width: 100%;

  align-items: flex-end;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#display {
  min-height: 1.2em;
  font-size: 1.1em;
}

#display-formula {
  flex: 1 1;
  font-size: 0.8em;
  padding: 0.5em 0em;
  display: flex;
  flex-wrap: wrap;
  min-height: 2.5em;
  justify-content: flex-end;
  align-items: center;
  word-wrap: wrap;
  word-break: break-all;
  color: yellowgreen;
}

.button {
  background-color: #222;
  color:white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#clear{
  background-color: tomato;
}

#equals{
  background-color: var(--main-color);
}
#calculator {
  position: relative;
  font-size: 1rem;
  padding: 0.5em;
  width: 22em;
 
  height: 35em;
  background-color: #eee;
  border-radius: 30px;
  display: grid;
  grid-row-gap: 0.5em;
  grid-column-gap: 0.5em;
  grid-template:
    "display display display display" 2fr
    "clear clear divide multiply" 1fr
    "seven eight nine subtract" 1fr
    "four five six add" 1fr
    "one two three equals" 1fr
    "zero zero decimal equals" 1fr;
}

#calculator::after {
  display: block;
  content: "By Nicolas Garay";
  position: absolute;
  bottom: -2em;
  width: 100%;
  text-align: center;

  font-size: 1rem;
  margin: 0;
}

:root {

  --main-color:#17f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Share Tech Mono";
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #17f;
  background-color: var(--main-color);
}

