#display-container {
  padding: 0.5em;
  font-size: 1.5rem;
  background-color: #222;
  grid-area: display;
  border-radius: 30px 30px 0 0;
  text-align: right;
  color: white;
  width: 100%;

  align-items: flex-end;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#display {
  min-height: 1.2em;
  font-size: 1.1em;
}

#display-formula {
  flex: 1;
  font-size: 0.8em;
  padding: 0.5em 0em;
  display: flex;
  flex-wrap: wrap;
  min-height: 2.5em;
  justify-content: flex-end;
  align-items: center;
  word-wrap: wrap;
  word-break: break-all;
  color: yellowgreen;
}
