@import "https://fonts.googleapis.com/css?family=Share+Tech+Mono";

:root {

  --main-color:#17f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Share Tech Mono";
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
}
